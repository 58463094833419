
.sectionFirst-container {
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 10rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.LeftContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
}

.sectionFirst-text {
    text-align: justify;
    text-justify: inter-word;
}

.LeftContainer > :last-child {
    padding-bottom: 0;
}

.rightContainer {
    flex: 1;
}

.rightContainer :first-child {
    padding-bottom: 0;
}

.boxText {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

@media (max-width: 1000px) {
    .sectionFirst-container {
        gap: 5rem;
    }

    .LeftContainer {
        gap: 0rem;
    }

    .rightContainer > :first-child {
        padding-bottom: 0;
    }
}

@media (max-width: 740px) {
    .sectionFirst-container {
        flex-direction: column;
        padding-bottom: 0rem;
        gap: 0;
    }

    .LeftContainer > :last-child {
        padding-bottom: 5rem;
    }

    .rightContainer > :first-child {
        padding-bottom: 5rem;
    }

    .boxText {
        text-align: center;
        gap: 2rem;
    }

    .LeftContainer {
        gap: 0;
    }
}

@media (max-width: 480px) {
    .sectionFirst-container {
        padding-top: 5rem;
    }
}

@media (max-width: 1100px) and (min-width: 820px) and (max-height: 1400px) and (orientation: portrait) {
    .sectionFirst-container {
        gap: 7rem;
    }
}

@media (max-width: 815px) and (max-height: 1300px) and (orientation: portrait) {
    .sectionFirst-container {
        gap: 5rem;
        padding: 5rem 0;
    }
}


@media (max-width: 450px) and (max-height: 950px) and (orientation: portrait) {
    .sectionFirst-container {
        gap: 0rem;
    }

    .rightContainer :first-child  {
        padding-bottom: 0;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .sectionFirst-container {
        gap: 7rem;
    }

    .LeftContainer {
        gap: 0;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .sectionFirst-container {
        padding: 3.5rem 0;
    }

    .sectionFirst-container {
        gap: 5rem;
    }

    .rightContainer :first-child {
        padding-bottom: 0;
    }
}

@media (max-width: 700px) and (max-height: 400px) and (orientation: landscape) {
    .LeftContainer > :last-child {
        padding-bottom: 0;
    }
}