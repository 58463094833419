
@font-face {
    font-family: 'Belleza';
    src: url('/public/fonts/Belleza-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('/public/fonts/Montserrat-Light.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anderson Grotesk Light';
  src: url('/public/fonts/AndersonGrotesk-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anderson Grotesk';
  src: url('/public/fonts/AndersonGrotesk.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anderson Grotesk Bold';
  src: url('/public/fonts/AndersonGrotesk-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
  
