
@import './css/fonts.css';


html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

body {
  margin: 0;
  font-family: 'Montserrat Light', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backgroundRadius {
  background-color: #111312;
}

.componentsContainer {
  position: sticky;
  background-color: #fff;
  padding-left: 6vw;
  padding-right: 6vw;
  margin-top: -10vh;

  z-index: 5;

  border-radius: 10vh 10vh 0 0;
  -webkit-border-radius: 10vh 10vh 0 0;
  -moz-border-radius: 10vh 10vh 0 0;
  -ms-border-radius: 10vh 10vh 0 0;
  -o-border-radius: 10vh 10vh 0 0;
}

h1 {
  font-family: 'Anderson Grotesk';
}

h2 {
  font-family: 'Anderson Grotesk';
  font-size: 4.0vh;
  font-weight: 600;
  line-height: 4.5vh;

  margin: 0;
}

p,
.team-content ul li {
  font-size: 1.25rem;
  line-height: 1.8rem;
  margin: 0;
}

.team-content ul li {
  margin-bottom: 0 !important;
}

@media (max-width: 1350px) {
  h2 {
    font-size: 1.9rem;
    line-height: 2.2rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1.2rem;
    line-height: 1.45rem;
  }
}

@media (max-width: 1010px) {
  h2 {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1.1rem;
    line-height: 1.37rem;
  }
}

@media (max-width: 1100px) {
  .componentsContainer {
    border-radius: 5vh 5vh 0 0;
    -webkit-border-radius: 5vh 5vh 0 0;
    -moz-border-radius: 5vh 5vh 0 0;
    -ms-border-radius: 5vh 5vh 0 0;
    -o-border-radius: 5vh 5vh 0 0;
  }
}

@media (max-width: 740px) {
  .componentsContainer {
    padding-left: 7vw;
    padding-right: 7vw;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1rem;
    line-height: 1.2rem;
  }

}

@media (max-width: 400px) {
  h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 0.9rem;
    line-height: 1.12rem;
  }
}

