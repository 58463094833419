
.images-container {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;

    margin: 0 auto;
    background-color: #E3E3E3;
    /* position: relative; */
    padding: 3rem 9rem;
    margin-bottom: 5rem;
}

@media (max-width: 1100px) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imgHiddenKnight {
        display: none;
    }
}



@media (max-width: 625px) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imageSectionFourth {
        padding: 0 1rem;
    }

    .imgHiddenKnight,
    .imgHiddenLabak {
        display: none;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imgHiddenKnight {
        display: none;
    }
}