
.libraly-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(0deg, #9C686E, #362426); /* gradient similar to the uploaded image */
    height: 110vh;
    max-width: 100vw;
    color: #ffffff;
}

.image {
    flex: 1;
    /* width: 50%; */
    height: 100%;
}

.libraly-image {
    position: static;
    width: 100%;
    height: 100%;
    background-image: url('/public/images/libraly.png');
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.imageMobile {
    display: none;
}

.libralyTitle {
    width: 100%;
    text-align: center;
    font-family: 'Belleza';
    font-size: 9.6vw;
    line-height: 1.1;
    margin: 0;

    padding-bottom: 5.5rem;
}

.libraly-content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* max-width: 50%; */
    height: 100%;

    padding-bottom: 3rem;
    z-index: 2;
}

.textContainerLibraly {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    gap: 4rem;
    
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 7rem;
}

.titleContainerLibraly {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    gap: 0rem;
}

.batchContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    
    margin-right: 5rem;
}

.batch {
    width: 11vw;
    height: 11vw;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    rotate: -13.32deg;

    margin-top: 3rem;

    font-size: 2.4rem;
    font-family: 'Anderson Grotesk bold';
    font-weight: 400;
    text-align: center;
    color: #000;
}

.libraly-content p {
    font-size: 1.85vw;
    line-height: 7vh;
    margin: 0;
}

@media (max-width: 1570px) {
    .textContainerLibraly p {
       line-height: 3.5rem;
    }
}

@media (max-width: 1370px) {
    .textContainerLibraly p {
       line-height: 2.7rem;
    }

    .batch {
        margin-top: -2rem;
        font-size: 2.1rem;
    }
}

@media (max-width: 1300px) {
    .textContainerLibraly {
        padding-right: 3rem;
    }

    .batch {
        font-size: 1.9rem;
    }
}

@media (max-width: 1100px) {
    .textContainerLibraly p{
        line-height: 2.5rem;
    }

    .batch {
        font-size: 1.6rem;
    }
}

@media (max-width: 950px) {
    .textContainerLibraly p{
        line-height: 2.3rem;
    }

    .batch {
        width: 12vw;
        height: 12vw;
    }
}

@media (max-width: 870px) {
    .textContainerLibraly {
        padding-right: 3rem;
    }

    .textContainerLibraly p{
        line-height: 2.1rem;

        font-size: 1.17rem;
    }

    .batch {
        width: 12.5vw;
        height: 12.5vw;
        font-size: 1.5rem;
    }
}

@media (max-width: 870px) {
    .textContainerLibraly p{
        line-height: 1.9rem;

        font-size: 1.1rem;
    }

    .batch {
        width: 12.7vw;
        height: 12.7vw;
        font-size: 1.5rem;
    }
}

@media (max-width: 760px) {
    .textContainerLibraly p{
        line-height: 1.8rem;
    }

    .batch {
        width: 12.7vw;
        height: 12.7vw;
        font-size: 1.4rem;
    }
}

@media (max-width: 700px) {
    .textContainerLibraly p{
        font-size: 1.0rem;
    }

    .batch {
        font-size: 1.3rem;
    }

    .libralyTitle  {
        font-size: 10.5vw;
    }
}

@media (max-width: 650px) {
    .batch {
        font-size: 1.2rem;
    }
}

@media (max-width: 625px) {
    .textContainerLibraly{
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;

        gap: 1rem;
    }

    .batch {
        width: 17.3vw;
        height: 17.3vw;
        font-size: 1.1rem;
        margin-top: 0;
    }

    .textContainerLibraly {
        flex-direction: row;
    }

    .imageMobile {
        display: unset;
        width: 100%;
        height: 50%;
    }

    .image {
        display: none;
    }

    .libralyTitle  {
        font-size: 16.5vw;
    }
}

@media (max-width: 480px) {
    .libraly-section {
        height: 110vh;
    }

    .libralyTitle {
        padding-bottom: 5.5rem;
    }
}

@media (max-width: 430px) {

    .batch {
        width: 19.3vw;
        height: 19.3vw;
        font-size: 1.1rem;
        margin-top: 0;
    }
}

@media (max-width: 1100px) and (min-width: 820px) and (max-height: 1400px) and (orientation: portrait) {
    .libraly-section {
        max-height: 70vh;
    }

    .textContainerLibraly p {
        font-size: 1.7rem;
        line-height: 2.4rem;
     }

    .libralyTitle {
        padding-bottom: 7.5rem;

        font-size: 6.7rem;
    }

    .batch {
        width: 17.3vw;
        height: 17.3vw;
        font-size: 2.5rem;
        margin-top: 0;
    }
}

@media (max-width: 810px) and (max-height: 1300px) and (orientation: portrait) {
    .libraly-section {
        height: 55vh;
    }

    .libralyTitle {
        padding-bottom: 6.5rem;
    }
}

@media (max-width: 820px) and (max-height: 1100px) and (orientation: portrait) {
    .libraly-section {
        height: 75vh;
    }
}

@media (max-width: 450px) and (max-height: 950px) and (orientation: portrait) {
    .libraly-section {
        height: 99vh;
    }

    .textContainerLibraly {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .titleContainerLibraly {
        padding-top: 2rem;
    }

    .libralyTitle {
        padding-bottom: 3.5rem;
    }
}

@media (max-width: 390px) and (max-height: 700px) and (orientation: portrait) {
    .libraly-section {
        height: 110vh;
    }

    .textContainerLibraly {
        padding-top: 2rem;
    }

    .libralyTitle {
        padding-bottom: 3.5rem;
    }
}

@media (max-width: 1200px) and (max-height: 430px) and (orientation: landscape) {
    .libraly-section {
        min-height: 130vh;
    }
} 

@media (max-width: 1100px) and (max-height: 850px) and (orientation: landscape) {
    .libraly-section {
        min-height: 70vh;
        height: 100vh;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .libraly-section {
        min-height: 145vh;
    }

    .libralyTitle {
        padding-bottom: 2.5rem;
    }
} 

@media (max-width: 800px) and (max-height: 400px) and (orientation: landscape) {
    .textContainerLibraly {
        padding-top: 2rem;
    }

    .libralyTitle {
        padding-bottom: 1rem;
    }
}
