

.sectionSecond-container {
    max-width: 100%;
    min-height: 60vh;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 7rem;
    background-color: #E3E3E3;
}

.sectionSecondTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    height: 100%;
    margin: 0;
    margin-left: -8rem;

    font-family: 'Belleza';
    font-size: 13.5vh;

    transform: rotate(-90deg);
    white-space: nowrap;
}

.grafContainer {
    width: 42%;
}

.hidden {
    visibility: hidden;
}

.none {
    display: none;
}

@media (max-width: 1800px) {
    .sectionSecond-container {
        min-height: 45vh;
    }

    .grafContainer {
        min-width: 35%;
        margin-left: 3rem;
    }

    .sectionSecondTitle {
        margin-left: -3rem;
        font-size: 5.5rem;
    }
}

@media (max-width: 1100px) and (orientation: portrait) {
    .sectionSecond-container {
        min-height: 40vh;
    }

    .grafContainer {
        min-width: 54%;
        margin-left: -2.5rem;
    }

    .sectionSecondTitle {
        margin-left: -3rem;
        font-size: 6rem;
    }
}

@media (max-width: 900px) {
    .sectionSecond-container {
        min-height: 40vh;
    }

    .grafContainer {
        min-width: 60%;
        margin-left: -4.5rem;
    }

    .sectionSecondTitle {
        margin-left: -4rem;
        font-size: 6rem;
    }
}


@media (max-width: 740px) {

    .sectionSecond-container {
        min-height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
        margin-bottom: 5rem;
        gap: 1rem;
    }

    .sectionSecondTitle {
        margin-left: 0rem;
        font-size: 4rem;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    .hidden {
        display: none;
    }

    .grafContainer {
        width: 95%;
        margin-left: 0;
    }
}

@media (max-width: 815px) and (max-height: 1300px) and (orientation: portrait) {
    .sectionSecond-container {
        min-height: 35vh;
        margin-bottom: 5rem;
    }

    .sectionSecondTitle {
        font-size: 5rem;
        margin-left: -2rem;
    }
}

@media (max-width: 450px) and (max-height: 950px) and (orientation: portrait) {
    .sectionSecondTitle {
        margin-left: 0rem;
        font-size: 4.5rem;
    }
}

@media (max-width: 1300px) and (max-height: 850px) and (orientation: landscape) {

    .sectionSecond-container {
        min-height: 55vh;
        margin-bottom: 7rem;
    }

    .grafContainer {
        min-width: 50%;
        margin-left: 3rem;
    }

    .sectionSecondTitle {
        margin-left: -3rem;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .sectionSecond-container {
        min-height: 90vh;
        margin-bottom: 3.5rem;
    }

    .grafContainer {
        min-width: 55%;
        margin-left: 0;
    }

    .sectionSecondTitle {
        font-size: 4.5rem;
        margin-left: -2rem;
    }
} 

@media (max-width: 800px) and (max-height: 400px) and (orientation: landscape) {

    .sectionSecondTitle {
        font-size: 4.5rem;
    }
} 

