
.team-section {
    max-width: 100%;
}

.sectionFifthTitle {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 7.2rem;
    padding-right: 7.2rem;
    margin-bottom: 5rem;

    text-align: center;
    font-size: 3.3rem;
    font-family: 'Belleza';
    line-height: 4.9rem;
}

.teamContainer {
    display: flex;
    background-color: #2a2a2a;
    color: #ffffff;
}

.team-image {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5vh 7vw 5vh 7vw;
    gap: 2.7rem;
}

.team-introduction {
    width: 79%;
    margin: 0;
}

.team-content h2 {
    margin: 0;
}

.team-content p {
    margin: 0;

    text-align: justify;
    text-justify: inter-word;
}

.team-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


@media (max-width: 1400px) {
  
    .sectionFifthTitle {
        padding: 0 3rem;

        font-size: 2.9rem;
        line-height: 3.9rem;
    }
}

@media (max-width: 1100px) {
  
    .sectionFifthTitle {
        padding: 0 3rem;

        font-size: 2.3rem;
        line-height: 2.75rem;
    }
}

@media (max-width: 625px) {
    .sectionFifthTitle {
        padding: 0 2rem;
        font-size: 1.7rem;
        line-height: 1.9rem;
    }

    .teamContainer {
        flex-direction: column;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .sectionFifthTitle {
        padding: 0 2rem;
        font-size: 1.9rem;
        line-height: 2.9rem;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .team-content {
        padding: 8vh 7vw 8vh 7vw;
    }
}