

.footer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    width: 100%;
    height: auto;
    background-color: #333;
    color: #fff;

    padding: 0 1rem;
}

.navbar-footer-info {
    display: flex;
    flex-direction: row;
    padding: 2.3vw 3.5vh;
    font-size: 1.7vh;
    gap: 4rem;
}

.text-info {
    padding-bottom: 1vh;
}

.last-info{
    padding-bottom: 0;
}

.bold {
    font-weight: bold;
}

.companyName {
    font-size: 2.15vh;
    font-family: Anderson Grotesk Light;
}

.underLine {
    border-bottom: 1px solid #fff;
}

.underLineTransparent {
    border-bottom: 1px solid transparent;
}

@media (max-width: 1570px) {
    .navbar-footer-info {
        font-size: 1.8vh;
    }

    .companyName {
        font-size: 2.0vh;
    }
}

@media (max-width: 1200px) {
    .navbar-footer-info {
        padding-top: 1rem;
        font-size: 0.9rem;
    }

    .companyName {
        font-size: 1.1rem;
    }
}

@media (max-width: 630px) {
    .navbar-footer-info {
        flex-wrap: wrap;
        gap: 0rem;        
    }

    .navbar-footer-info > :nth-child(2) {
        margin-right: 2.1rem;
    }

    .infoContainerFirst {
        width: 100%;
    }

    .underLine {
        border-bottom: none;
    }

    .companyNameHidden {
        display: none;
    }

    .infoContainerSecond,
    .infoContainerThird {
        margin-top: 0.6rem;
    }
}

@media (max-width: 600px) {
    .navbar-footer-info {
        font-size: 0.8rem;
    }

    .companyName {
        font-size: 1.1rem;
    }
}

@media (max-width: 380px) and (max-height: 850px) and (orientation: portrait) {
    .navbar-footer-info {
        font-size: 0.7rem;
    }

    .navbar-footer-info {
        display: flex;
        flex-direction: column;
    }
  }
  
  @media (max-width: 932px) and (max-height: 430px) and (orientation: landscape) {
    .navbar-footer-info {
        font-size: 0.8rem;
    }
}
