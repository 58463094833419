

.container {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto;
    padding: 0 6vw;
    gap: 10rem;
    padding-bottom: 7rem;
}

.containerLeft {
    flex: 1;
}

.boxText {
    padding-bottom: 5rem;
}


/*  right */
.boxList {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.1vh 0;
    border-bottom: 1px solid #242B35;
}

.features-list li:first-child {
    border-top: 1px solid #242B35;
}

.features-list li:last-child {
    border-bottom: none;
}

.process-number {
    font-size: 3.5vw;
    font-weight: 600;
    color: #333;

    width: 5vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 2vw;
    padding-left: 0vw;
    padding-bottom: 0.5vw;

    background-color: #E3E3E3;
    border: 1px solid #242B35;
    border-radius: 50%;
}

.number {
    margin-bottom: -1rem;

    font-family: 'Anderson Grotesk Bold';
    font-weight: 400;
}

.process-text {
    display: flex;
    min-height: 105px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.process-text h2 {
    margin: 0;
    /* margin-bottom: 0.5vh; */
    text-transform: uppercase;
    color: #333;
}

.process-text p {
    margin: 0.5vh 1rem 0 0;
    /* font-size: 1.2vw; */
    /* color: #666; */
}

.containerRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    max-height: 100%;
    height: auto;
}

.containerRight > :last-child {
    padding-bottom: 0;
}

.imageProcess {
    /* width: 45%; */
    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;
}

.imageProcess img {
    object-fit: cover;
    width: auto;
}

@media (max-width: 1000px) {
    .container {
        gap: 5rem;
    }

    .imageProcess {
        width: 100%;
    }

    .number {
        margin-bottom: -0.5rem;
    }
}

@media (max-width: 815px) {
    .content {
        width: 45%;
        padding-right: 4vw;
    }
   
    .content h2 {
        margin-bottom: 1rem;
    }

    .imageProcess img {
        object-position: 57% 0;
    }

    .features-list li {
        align-items: flex-start;
    }
    
    .process-text {
        display: flex;
        margin-top: -0.2rem;
    }

    .process-text h2 {
        margin-left: 0.5rem;
        margin-bottom: -0.3rem;
        padding-top: 0.5rem;
    }

    .process-text p {
        margin-top: 0.9rem;
        margin-left: -3.5rem;
    }
}

@media (max-width: 755px) {
    .features-list li {
        align-items: center;
    }

    .number {
        margin-bottom: -0.43rem;
    }

    .process-text {
        display: flex;
        margin-top: 0rem;
    }

    .process-text h2 {
        margin-left: 0rem;
        margin-bottom: -0.3rem;
    }

    .process-text p {
        margin-top: 0.5rem;
        margin-left: 0rem;
    }
}

@media (max-width: 740px) and (orientation: portrait) {
    .container {
        flex-direction: column;
        gap: 5rem;
        padding: 0 7vw;
        padding-bottom: 5rem;
    }

    .containerLeft {
        padding-right: 0;
    }

    .content {
        width: 100%;
        padding: 0 1rem 1rem 1rem;
    }
   
    .content h2 {
        margin-bottom: 1rem;
    }

    .imageProcess {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .imageProcess img {
        object-position: 0 0;
    }

    
    .boxList {
        gap: 2rem;
    }

    .process-text {
        display: flex;
        margin-top: 0rem;
    }

    .process-text h2 {
        margin-left: 0rem;
        margin-bottom: -0.3rem;
        padding-top: 0;
    }

    .process-text p {
        margin-top: 0.5rem;
        margin-left: 0rem;
    }
}

@media (max-width: 600px) {
    .process-number {
        display: none;
    }
}

@media (max-width: 1100px) and (max-height: 1400px) and (orientation: portrait) {
    .container {
        gap: 5rem;
    }
}

@media (max-width: 815px) and (max-height: 1300px) and (orientation: portrait) {
    .container {
        padding-bottom: 3.5rem;
    }
    .process-text {
        min-height: auto;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .container {
        gap: 5rem;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .container {
        padding-bottom: 3rem;
    }
    
    .containerRight > :last-child {
        padding-bottom: 0;
    }

    .features-list li {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .process-text {
        min-height: auto;
    }
}
