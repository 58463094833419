

.sectionSixth {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10rem;
    padding: 7rem 6vw;
}

.sectionSixth > * {
    padding-bottom: 0;
}

.boxTextLeft,
.boxTextRight {
    flex: 1;
}

.textContainer {
    text-align: justify;
    text-justify: inter-word;
}

@media (max-width: 1000px) {
    .sectionSixth {
        gap: 5rem;
    }
}

@media (max-width: 740px) {
    .sectionSixth {
        flex-direction: column;
        padding: 5rem 7vw 5rem 7vw;
        gap: 0;

        text-align: center;
    }

    .sectionSixth > :first-child {
        padding-bottom: 5rem;
    }
}

@media (max-width: 1100px) and (min-width: 820px) and (max-height: 1400px) and (orientation: portrait) {
    .sectionSixth {
        gap: 7rem;
    }
}

@media (max-width: 815px) and (max-height: 1300px) and (orientation: portrait) {
    .sectionSixth {
        gap: 5rem;
        padding: 5rem 5vw;
    }
}

@media (max-width: 450px) and (max-height: 950px) and (orientation: portrait) {
    .sectionSixth {
        gap: 0rem;
        padding: 5rem 7vw;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .sectionSixth {
        gap: 5rem;
    }
}

@media (max-width: 930px) and (max-height: 430px) and (orientation: landscape) {
    .sectionSixth {
        padding: 3.5rem 5vw;
    }

    .sectionSixth > * {
        padding-bottom: 1rem;
    }
}

@media (max-width: 700px) and (max-height: 400px) and (orientation: landscape) {
    .sectionSixth > :first-child {
        padding-bottom: 0;
    }
}